import React from "react";
import { useEffect } from "react";
import Home from "../components/Home/Home";
import Layout from "../components/Layout";

export const IndexPageTemplate = () => {
  return <Layout>{/* <Home /> */}</Layout>;
};

const IndexPage = () => {
  useEffect(() => {
    location.href = "https://www.sensetask.com";
  }, []);

  return <div />;

  return <IndexPageTemplate />;
};

// export const pageQuery = graphql`
//   query IndexPageTemplate {
//     markdownRemark(
//       frontmatter: { templateKey: { eq: "index-page" } }
//       th
//     ) {
//       frontmatter {
//         templateKey
//       }
//     }
//   }
// `;

export default IndexPage;
